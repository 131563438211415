import { useLocation, useNavigate } from "react-router";
import { CabButton } from "./CabButton";
import { IoArrowBack } from "react-icons/io5";
import { CabIcon } from "./CabIcon";


const BackButton = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <CabButton
      size="small"
      icon={<CabIcon alt='Back' Icon={IoArrowBack} />}
      buttonType="tertiary"
      onClick={() => navigate(-1)}
    >
      {location.state?.from?.displayName || 'Back'}
    </CabButton>
  );
};

export default BackButton;
